import React, { useState } from 'react';
import './Welcome.css';
import logo from '../logo.png';
import profileIcon from '../jamiehooper89@gmail.com.png';

const Welcome = ({ email, onSend }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSend = () => {
    onSend(subject, message);
  };

  return (
    <div className="container">
      <div className="left-section">
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <div className="text-container">
          <h1 className="sign-in-text">Welcome</h1>
          <div className="dropdown-container">
            <div className="dropdown-content">
              <img src={profileIcon} alt="Profile icon" className="profile-icon" />
              <span className="email">{email}</span>
              <svg aria-hidden="true" className="dropdown-arrow" fill="currentColor" focusable="false" width="24px" height="24px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                <path d="M7 10l5 5 5-5z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="input-group">
          <div className="input-container">
            <input
              type="text"
              className="input"
              placeholder="Subject"
              value={subject}
              onChange={handleSubjectChange}
            />
            <label className="input-label">Subject</label>
          </div>
          <div className="input-container message">
            <textarea
              className="input"
              placeholder="Message"
              value={message}
              onChange={handleMessageChange}
            />
            <label className="input-label">Message</label>
          </div>
        </div>
        <button className="next-button" onClick={handleSend}>
          Send
        </button>
      </div>
    </div>
  );
};

export default Welcome;
