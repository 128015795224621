import React, { useEffect } from 'react';
import './Confirmation.css';

const Confirmation = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = 'https://jamieparishooper.com';
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="confirmation-container">
      <h1>Your message has been sent!</h1>
      <p>You will be redirected to the homepage shortly.</p>
    </div>
  );
};

export default Confirmation;
